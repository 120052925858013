<template>
  <div>
    <div class="container">
      <h1 class="mt-12 text-center">Pricing</h1>
    </div>
  </div>
</template>

<script>
export default {

}
</script>
